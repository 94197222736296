<template>
  <div class="col-12 col-sm-12 col-md-4 wrapper d-flex flex-column">
    <div class="fr-alert fr-alert--error ri-alert-line">
      <p class="fr-alert__title">
        {{ message }}
      </p>
    </div>

    <div class="boutons">
      <button class="btn ri-home-2-line" @click="pageAcceuil()">
        Page d'accueil
      </button>
    </div>
  </div>
</template>

<script>
import codes from '../assets/codesErreurRetryView.json';
export default {
  data() {
    return {
      message:
        "Erreur d'authentification ! Une erreur s'est produite lors du processus d'authentification. Veuillez réessayer de vous connecter."
    };
  },
  mounted() {
    this.getMessage();
  },
  methods: {
    pageAcceuil() {
      this.$router.push({ name: 'Mon compte' }).catch(() => {});
    },
    getMessage() {
      let urlParams = new URLSearchParams(window.location.search.slice(1));
      if (!urlParams) {
        return;
      }
      let statusMessage = urlParams.get('statusMsg');
      if (!statusMessage) {
        return;
      }
      if (statusMessage.startsWith('COPRO_ERROR')) {
        let codesFiltered = codes.codes.filter((e) => e.code === statusMessage);
        if (codesFiltered && codesFiltered.length === 1) {
          this.message = codesFiltered.pop().message;
        } else {
          this.message =
            "Code erreur non attendu : Votre demande n'a pas pu aboutir. Veuillez la renouveler ultérieurement.";
        }
      } else {
        this.message = statusMessage;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.d-flex {
  display: block !important;
}
.champs-obligatoires {
  margin-left: 18rem;
  font-size: 13px;
  color: #6a6a6a;
}
.fr-input {
  width: 357px;
  height: 40px;
  margin: 0px 0px 0px 0px;
  padding: 8px 16px 8px 16px;
  border-radius: 0.25rem 0.25rem 0px 0px !important;
  background-color: #f0f0f0;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}
.fr-alert {
  margin: 2% 2% 2% 2%;
}
a:hover {
  color: #1e1e1e;
}
a {
  box-shadow: none !important;
}
a:hover {
  color: #d94400;
  text-decoration: underline;
}
a {
  cursor: pointer;
  color: inherit;
  box-shadow: 0 1px 0 0 currentColor;
}
h1 {
  color: $base-color;
  display: flex;
}

.link-button {
  background: 0 0;
  color: #ff5000;
  width: 100%;
  padding-top: 1rem;
}

.container {
  width: 65%;
  padding: 3%;
  padding-right: 2%;
  border: 2px solid #dddddd;
  margin: 2%;
  margin-left: 24%;
}

.title-bloc {
  font-size: 1rem;
}
.btn {
  background: $base-color;
  color: white;
  font-size: 14px;
  border: 0;
  width: 15rem;
  margin-left: 1rem;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  padding: 15px 32px;
  border-radius: 0;
}

.btn:hover {
  background-color: #1212ff;
}

.boutons {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
}

.form-button {
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  width: 100%;
  padding-left: 5rem;
  text-align: center;
  padding-right: 2.5rem;
}

.form-button-echec {
  display: flex;
  width: 100%;
  padding-top: 0.5rem;
  height: 3.5rem;
}

.img-echec-mobile {
  width: 90%;
}
.field {
  padding: 20px 0px 0 55px;
}

@media only screen and (max-width: 760px) {
  .container {
    min-height: 26rem;
  }

  .form-button {
    width: 50%;
    margin-left: 15%;
  }

  .form-button-echec {
    display: block;
    width: 90%;
    padding-top: 0rem;
    height: 3.5rem;
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .button-echec {
    margin-left: 0rem;
    margin-top: 0.5rem;
    height: 2rem;
  }

  .title-bloc {
    font-size: 15px;
  }

  .img-mobile {
    width: 90%;
    margin-right: 1rem;
  }

  .finalisation-titre {
    margin-left: 5rem;
  }
}
</style>
